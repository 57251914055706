import styles from './index.module.scss';

const OnasC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.OnasC_div}>
        <h2>РЕКВИЗИТЫ КОМПАНИИ</h2>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <div className={styles.img_div}>
              <img src={require('../../assets/o_nas_imgs/o_nas_12.png')} />
            </div>
          </div>
          <div className={styles.info_div}>
            <p>Организация: ООО «Главоблснаб»</p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_13.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              ИНН/КПП: <br />
              7720473660 / 772001001
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_14.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Юр. Адрес: <br /> 111673, г. Москва, ул. Салтыковская,
              <br /> дом 8, этаж 3, помещение 310
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_15.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Почтовый адрес: <br />
              111673, г. Москва, ул. Салтыковская, дом 8, этаж 3, помещение 310
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_16.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Филиал "Корпоративный"
              <br /> ПАО "Сбербанк" г. Москва
              <br /> Р/С: 40702810640000063831
              <br /> К/С: 30101810400000000225
              <br /> БИК: 044525225
              <br /> ОКПО: 40600299
              <br /> ОКВЭД: 46.73
              <br /> ОКАТО : 45263573000
              <br /> ОКТМО : 45308000000
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_17.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Генеральный директор:
              <br /> Бредников Дмитрий Владимирович
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_18.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Главный бухгалтер:
              <br /> Ванина Оксана Анатольевна
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnasC;

import styles from './index.module.scss';

const OnasP = () => {
  return (
    <div className={styles.root}>
      <div className={styles.OnasP_div}>
        <h2>ЗАВОДЫ-ПРОИЗВОДИТЕЛИ</h2>
        <div className={styles.img_info_div}>
          <p>
            «ГЛАВОБЛСНАБ» является официальным дилером товаров многих торговых марок (ТЕХНОНИКОЛЬ,
            ПЕРФЕКТА, БЕРГАУФ, МАГМА, НОВОБЛОК, ЛИТОКОЛ, АРХИН, ОСНОВИТ). Надежные отношения с
            производителями строительных и отделочных материалов позволяют нам поддерживать всю
            современную качественную линейку стройматериалов по дилерским ценам.
          </p>
        </div>
        <img src={require('../../assets/o_nas_imgs/o_nas_11.png')} />
      </div>
    </div>
  );
};

export default OnasP;

import styles from './index.module.scss';

const DostavkaInfo = () => {
  return (
    <div className={styles.root}>
      <div className={styles.dostavka_info_div}>
        <div className={styles.di_text_div}>
          <p>Отдел продаж</p>
          <p>
            Тел : <span className={styles.phone}> 8 (495) 215-24-75</span>. Ул.Салтыковская 8/18
          </p>
          <p>
            Email : <span className={styles.phone}> stroy@stroy2002.ru </span>
          </p>
          <p>
            Тел : <span className={styles.phone}> 8 (800) 775-64-52</span>. Ул. 8 Марта д.48 ( ЖК
            Гоголь Парк)
          </p>
          <p>
            Email:
            <span className={styles.phone}> mshumilin@stroy2002.ru </span>
          </p>
          <p>Наша компания организует быструю и бесперебойную доставку удобным для вас способом:</p>
          <ul>
            <li> автомобильным;</li>
            <li> железнодорожным;</li>
            <li> водным транспортом.</li>
          </ul>
          <p>Наши менеджеры подберут для вас самый выгодный способ получения товара.</p>
        </div>
        <div className={styles.di_img_div}>
          <img src={require('../../assets/gruzz.jpg')} />
        </div>
      </div>
    </div>
  );
};

export default DostavkaInfo;

export function sortCatalog(items, filter, search) {
  let arr = [];
  if (filter == 'все') {
    arr = items;
  } else if (filter == 'поиск' || filter == 'бренд') {
    let add_to_arr = false;
    let searchData = [];
    let itemName = [];
    let itemBrand = [];
    let itemData = [];

    search.split(' ').forEach((el) => {
      el != '' && searchData.push(el);
    });
    items.forEach((el) => {
      el.item_name.split(' ').forEach((el) => {
        el != '' && itemName.push(el);
      });
      el.item_brand.split(' ').forEach((el) => {
        el != '' && itemBrand.push(el);
      });
      itemData = [...itemName, ...itemBrand];
      for (let i = 0; i < searchData.length; i++) {
        add_to_arr = false;
        let confirmSearch = false;
        for (let g = 0; g < itemData.length; g++) {
          if (searchData[i].toLowerCase() == itemData[g].toLowerCase()) {
            confirmSearch = true;
            //console.log(searchData[i]);
            continue;
          }
        }
        if (confirmSearch == false) {
          //   console.log('stop');
          break;
        } else add_to_arr = true;
      }
      add_to_arr == true && arr.push(el);
      // console.log(add_to_arr);
      itemName = [];
      itemBrand = [];
      itemData = [];
    });
  } else {
    items.forEach((el) => {
      el.item_category == filter && arr.push(el);
    });
  }
  //console.log(filter);
  //console.log(arr);
  return arr;
}

import React from 'react';
import styles from './index.module.scss';
import img_s from '../../assets/other_imgs/e_sl.png';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';

const MineSlider = () => {
  const slides = useSelector((state) => state.data.data.options);
  // alert(JSON.stringify(slides));
  const mainSlide = slides != undefined ? slides[0].main_slider_img : ['empty'];

  const allSlides = [];
  const all_Slides = slides != undefined ? JSON.parse(slides[0].other_slider_img) : ['empty'];
  // alert(all_Slides);
  {
    slides != undefined &&
      all_Slides != null &&
      all_Slides.forEach((el) => {
        if (el === mainSlide) {
          allSlides.unshift(el);
        } else allSlides.push(el);
      });
  }
  const sliderImg_circles = slides != undefined && [...allSlides];
  {
    const first_slide = slides != undefined && allSlides[0];
    const last_slide = allSlides[allSlides.length - 1];
    slides != undefined && allSlides.unshift(last_slide);
    slides != undefined && allSlides.push(first_slide);
    //  console.log(allSlides);
  }

  //console.log(allSlides);
  const [pressArrow, setPressArrow] = React.useState('');
  const debL = (v) => {
    if (document.documentElement.scrollWidth > 2000) {
      setChangeSliderImg((-2000 - 2) * (v - 2));
    } else setChangeSliderImg((-document.documentElement.scrollWidth - 2) * (v - 2));
    //console.log(allSlides);
  };
  const debR = () => {
    if (document.documentElement.scrollWidth > 2000) {
      setChangeSliderImg(-2000 - 2);
    } else setChangeSliderImg(-document.documentElement.scrollWidth - 2);

    // console.log('deb');
  };

  const debounceL = React.useCallback(debounce(debL, 310), []);
  const debounceR = React.useCallback(debounce(debR, 310), []);

  const elRef = React.useRef();
  const [WindowWidth, setWidth] = React.useState(
    document.documentElement.scrollWidth < 2000 ? document.documentElement.scrollWidth : 2000,
  );

  const updateWidthAndHeight = () => {
    if (document.documentElement.scrollWidth > 2000) {
      setWidth(2000);
      setChangeSliderImg(-2000 - 2);
    } else {
      setWidth(document.documentElement.scrollWidth);
      setChangeSliderImg(-document.documentElement.scrollWidth - 2);
    }
    // console.log(document.documentElement.scrollWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });
  //const [maxSlide,setMaxSlide] = React.useState('');
  const maxSlide = slides != undefined && allSlides.length * -WindowWidth + WindowWidth + 1;
  //console.log(!maxSlide);

  const [changeSliderImg, setChangeSliderImg] = React.useState(
    document.documentElement.scrollWidth < 2000
      ? -document.documentElement.scrollWidth - 2
      : -2000 - 2,
  );
  React.useEffect(() => {
    //console.log(elRef.current);
    if (changeSliderImg == 0) {
      debounceL(allSlides.length);
    } else if (
      changeSliderImg == (-document.documentElement.scrollWidth - 2) * (allSlides.length - 1) &&
      document.documentElement.scrollWidth < 2000
    ) {
      debounceR();
    } else if (
      changeSliderImg == (-2000 - 2) * (allSlides.length - 1) &&
      document.documentElement.scrollWidth > 2000
    ) {
      debounceR();
    }
  }, [changeSliderImg]);

  React.useEffect(() => {
    //Implementing the setInterval method

    const interval = setInterval(() => {
      setPressArrow('right');
      changeSliderImg >= maxSlide
        ? setChangeSliderImg(changeSliderImg + -WindowWidth - 2)
        : setChangeSliderImg((-WindowWidth - 2) * 2);
    }, 30000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [changeSliderImg]);

  return (
    <div className={styles.root}>
      {
        //console.log(allSlides)
      }
      <div className={styles.img_circles}>
        {slides != undefined &&
          all_Slides != null &&
          sliderImg_circles.map((el, i) => {
            let coordinate = (-document.documentElement.scrollWidth - 2) * (i + 1);
            if (document.documentElement.scrollWidth > 2000) {
              coordinate = (-2000 - 2) * (i + 1);
            }
            return (
              <div
                value={coordinate}
                className={coordinate == changeSliderImg ? styles.circle_active : styles.circle}
                onClick={() => {
                  // alert(coordinate + ' ' + changeSliderImg);
                  setChangeSliderImg(coordinate);
                  setPressArrow('circle');
                  //  console.log(coordinate);
                }}
                key={i}>
                {}
              </div>
            );
          })}
      </div>
      <div className={styles.carousel}>
        <div className={styles.leftWall}></div>
        <div className={styles.rightWall}></div>
        <div
          onClick={() => {
            setPressArrow('left');
            changeSliderImg != 0 && setChangeSliderImg(changeSliderImg + WindowWidth + 2);
          }}
          className={styles.arrow_left}>
          <svg viewBox='0 0 100 100'>
            <path d='M70.787,27.267v8.294c0,0.571-0.305,1.1-0.8,1.385L49.776,48.615c-1.066,0.616-1.066,2.155,0,2.771l20.212,11.669   c0.495,0.286,0.8,0.814,0.8,1.385v8.294c0,1.231-1.333,2.001-2.399,1.385L29.013,51.385c-1.066-0.616-1.066-2.155,0-2.771   l39.375-22.733C69.454,25.266,70.787,26.035,70.787,27.267z' />
            <path d='M69.987,63.055l-2.376-1.372c-0.013,0.043-0.023,0.09-0.032,0.136l0.409,0.236c0.495,0.286,0.8,0.814,0.8,1.385v8.294   c0,1.008-0.894,1.693-1.803,1.575l1.404,0.81c1.066,0.616,2.399-0.154,2.399-1.385V64.44C70.787,63.868,70.482,63.34,69.987,63.055   z' />
            <path d='M49.776,48.615l20.212-11.669c0.495-0.286,0.8-0.814,0.8-1.385v-8.294c0-1.115-1.092-1.84-2.091-1.512   c0.054,0.16,0.091,0.328,0.091,0.512v8.294c0,0.571-0.305,1.1-0.8,1.385L47.776,47.615c-0.078,0.052-0.745,0.516-0.8,1.385   c-0.057,0.911,0.609,1.467,0.673,1.518c0.639,0.443,1.277,0.885,1.916,1.328c-0.032-0.112-0.07-0.312,0.019-0.51   c0.011-0.025,0.024-0.049,0.037-0.07C48.725,50.593,48.768,49.196,49.776,48.615z' />
          </svg>
        </div>

        {slides != undefined && (
          <div
            onClick={() => {
              //alert(maxSlide + ' ' + changeSliderImg);
              setPressArrow('right');

              changeSliderImg >= maxSlide && setChangeSliderImg(changeSliderImg + -WindowWidth - 2);
            }}
            className={styles.arrow_right}>
            <svg className={styles.arrow_svg} viewBox='0 0 100 100'>
              <path d='M28.213,27.267v8.294c0,0.571,0.305,1.1,0.8,1.385l20.212,11.669c1.066,0.616,1.066,2.155,0,2.771L29.013,63.055   c-0.495,0.286-0.8,0.814-0.8,1.385v8.294c0,1.231,1.333,2.001,2.399,1.385l39.375-22.733c1.066-0.616,1.066-2.155,0-2.771   L30.612,25.881C29.546,25.266,28.213,26.035,28.213,27.267z' />
              <path d='M69.987,48.615L30.612,25.881c-0.193-0.112-0.395-0.164-0.597-0.19l37.972,21.923c1.066,0.616,1.066,2.155,0,2.771   L28.612,73.119c-0.1,0.058-0.205,0.092-0.308,0.126c0.308,0.914,1.401,1.398,2.308,0.874l39.375-22.733   C71.054,50.77,71.054,49.23,69.987,48.615z' />
            </svg>
          </div>
        )}
        <div
          ref={elRef}
          className={
            (changeSliderImg == (-WindowWidth - 2) * (allSlides.length - 2) &&
              pressArrow == 'left') ||
            (changeSliderImg == -WindowWidth - 2 && pressArrow == 'right') ||
            pressArrow == 'circle'
              ? styles.all_img_div_moment
              : styles.all_img_div
          }
          style={{ transform: 'translatex(' + changeSliderImg + 'px)' }}>
          <div></div>
          {slides != undefined &&
            all_Slides != null &&
            allSlides.map((el, i) => {
              return (
                <div className={styles.slider_img_div} key={i}>
                  <img className={styles.slider_img} src={'https://glavoblsnab.store' + el} />

                  {
                    //maxSlide + ' ' + changeSliderImg
                  }
                </div>
              );
            })}
        </div>
      </div>
      {/* <div>
        <img src={img_s} />
      </div>
      <div>
        {slider_img_count.map((el, i) => {
          return <p key={i}></p>;
        })}
      </div>*/}
    </div>
  );
};

export default MineSlider;

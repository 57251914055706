import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getData } from './redux/slices/dataSlice';

const ItemsData = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    axios
      .post(
        'https://glavoblsnab.store/server/_index_server.php',
        {
          type_gos: 'get_magaz_items_data',
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => {
        console.log(data);
        dispatch(getData(data));
      })
      .then();
  }, []);
};

export default ItemsData;

import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { addItem, removeItem, clearItems } from '../../redux/slices/cartSlice';

const CartItem = (props) => {
  const dispatch = useDispatch();
  // console.log(props);
  return (
    <div className={styles.root}>
      <div className={styles.img}>
        <img src={'https://glavoblsnab.store/' + props.props.imageUrl} />
      </div>
      {props.props.variant != 'default' ? (
        <div className={styles.item_name_div}>
          <div>{props.props.title}</div>
          <div>{props.props.variant == 'default' ? '' : props.props.variant}</div>
        </div>
      ) : (
        <div className={styles.item_name_div_noVar}>
          <div>{props.props.title}</div>
          <div>{props.props.variant == 'default' ? '' : props.props.variant}</div>
        </div>
      )}
      <div className={styles.count_div}>
        <div>кол-во</div>
        <div>
          <div>
            <div
              onClick={() => {
                props.props.count != 1 && dispatch(removeItem(props.props));
              }}
              className={styles.minus}>
              <p>-</p>
            </div>
            <p>{props.props.count}</p>
            <div
              onClick={() => {
                dispatch(addItem(props.props));
              }}
              className={styles.plus}>
              <p>+</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.price}>
        цена : <br /> {props.props.price * props.props.count} ₽
      </div>
      <div
        className={styles.delX}
        onClick={() => {
          dispatch(clearItems(props.props));
        }}>
        X
      </div>
    </div>
  );
};

export default CartItem;

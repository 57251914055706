import ItemPagePlace from '../components/item_page_place';

function ItemScreen() {
  window.scrollTo(0, 0);
  return (
    <div>
      <ItemPagePlace />
    </div>
  );
}

export default ItemScreen;

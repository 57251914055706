import styles from './index.module.scss';
import { Link } from 'react-router-dom';

const OrderInfo = () => {
  return (
    <div className={styles.root}>
      <div className={styles.order_div}>
        <p>Заказ принят на обработку</p>
        <p>Мы свяжемся с вами в ближайшее время</p>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <div className={styles.link_to_main}>На главную</div>
        </Link>
      </div>
    </div>
  );
};

export default OrderInfo;

import styles from './index.module.scss';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const MapComponent = () => {
  return (
    <div className={styles.root}>
      <div className={styles.map_div}>
        <YMaps>
          <Map
            style={{ width: '100%', height: '400px' }}
            // className={styles.map}
            defaultState={{ center: [55.718072, 37.879148], zoom: 13 }}>
            <Placemark
              defaultGeometry={[55.730126, 37.869374]}
              properties={{
                //      hintContent: 'Собственный значок метки',
                //  balloonContent: 'Это красивая метка',
                iconCaption: 'Офис на Салтыковской',
              }}
            />
            <Placemark
              defaultGeometry={[55.706384, 37.898975]}
              properties={{
                //      hintContent: 'Собственный значок метки',
                //  balloonContent: 'Это красивая метка',
                iconCaption: 'Офис в Гоголь-Парке',
              }}
            />
          </Map>
        </YMaps>
        <div className={styles.map_info_div}>
          <h2>Как к нам проехать</h2>
          <p>Компания "Главоблснаб"</p>
          <p>г. Москва, ул. Салтыковская, д. 8/18</p>
          <p>г. Люберцы, ЖК Гоголь Парк, ул. 8 Марта д.48</p>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;

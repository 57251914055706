import styles from './index.module.scss';

const ItemPageInfo = ({ itemData }) => {
  const description = !itemData ? '' : itemData.item_description;
  const features = !itemData ? '' : itemData.item_features;
  const feat = !features ? '' : JSON.parse(itemData.item_features);

  //console.log(feat);
  return (
    <div className={styles.root}>
      {description != '' && (
        <div className={styles.info1}>
          <div>ОПИСАНИЕ</div>
          <div>{description}</div>
        </div>
      )}
      {feat != '' && (
        <div className={styles.info2}>
          <div>ХАРАКТЕРИСТИКИ</div>
          <div>
            <ul>
              {feat != '' &&
                feat.map((el, i) => {
                  return <li key={i}>{el}</li>;
                })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemPageInfo;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/heder';
import Main from './components/main';
import CallOrder from './components/call_order';
import ItemsData from './site_data';
import Footer from './components/footer';
import { BrowserRouter } from 'react-router-dom';

import { store } from './redux/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ItemsData />
    <BrowserRouter>
      <div className='header_div'>
        <Header />
      </div>

      <Main />

      <Footer />
      <CallOrder />
    </BrowserRouter>
  </Provider>,
);

import styles from './index.module.scss';
import React from 'react';
//'https://goose.su/img/items/324555/main_img/12.jpg'

const ItemPageSlider = ({ itemData }) => {
  const [currentPhoto, setCurrentPhoto] = React.useState(
    itemData.item_main_photo != null ? 'https://glavoblsnab.store/' + itemData.item_main_photo : '',
  );

  const otherPhotos = !itemData ? '' : JSON.parse(itemData.item_other_photos);
  // console.log(otherPhotos); // 'img/items/' + itemData.item_code
  // console.log(itemData);
  return (
    <div className={otherPhotos.length != 0 ? styles.root : styles.root2}>
      <div className={otherPhotos.length != 0 ? styles.chose_img_div : styles.chose_img_div_hide}>
        {otherPhotos.length !== 0 && (
          <img
            onClick={() => {
              setCurrentPhoto(
                itemData.item_main_photo != null
                  ? 'https://glavoblsnab.store/' + itemData.item_main_photo
                  : '',
              );
            }}
            src={
              itemData.item_main_photo != null
                ? 'https://glavoblsnab.store/' + itemData.item_main_photo
                : ''
            }
            alt=''
          />
        )}
        {itemData &&
          //otherPhotos.length != 1 &&
          otherPhotos.map((el, i) => {
            // console.log(el);
            return (
              <img
                onClick={() => {
                  setCurrentPhoto('https://glavoblsnab.store' + el);
                }}
                key={i}
                src={'https://glavoblsnab.store' + el}
              />
            );
          })}
        {
          //console.log(otherPhotos.length)
        }
      </div>
      <div className={styles.show_img_div}>
        <img src={currentPhoto} alt='' />
      </div>
    </div>
  );
};

export default ItemPageSlider;

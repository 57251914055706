import React from 'react';

import CatalogCategoryPanel from '../components/catalog_category_panel';
import ItemsCatalogPlace from '../components/items_catalog_place';

const CatalogScreen = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <CatalogCategoryPanel />
      <ItemsCatalogPlace />
    </div>
  );
};

export default CatalogScreen;

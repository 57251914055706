import React from 'react';
import HeaderPage from '../components/header_page';
import MapComponent from '../components/map';
import OnasA from '../components/o_nas_a';
import OnasB from '../components/o_nas_b';
import OnasC from '../components/o_nas_c';
import OnasP from '../components/o_nas_partners';

const AboutUs = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <HeaderPage value={'О НАС'} />
      <OnasA />
      <OnasB />
      <OnasP />
      <MapComponent />
      <OnasC />
    </div>
  );
};

export default AboutUs;

import p_img_1 from './1-o.png';
import p_img_2 from './2-o.png';

import p_img_3 from './3-o.png';
import p_img_4 from './4-o.png';

import p_img_5 from './5-o.png';
import p_img_6 from './6-o.png';

import p_img_7 from './7-o.png';
import p_img_8 from './8-o.png';

import p_img_9 from './9-o.png';
import p_img_10 from './10-o.png';

import p_img_11 from './11-o.png';
import p_img_12 from './12-o.png';

import p_img_13 from './13-o.png';
import p_img_14 from './14-o.png';
const arrPartnersImg = [
  { partner: p_img_1, brand_name: 'Технониколь', img_type: 'catalog' },
  { partner: p_img_2, brand_name: 'Acoustic group', img_type: 'catalog' },
  { partner: p_img_3, brand_name: 'Perfekta', img_type: 'catalog' },
  { partner: p_img_4, brand_name: 'Архин', img_type: 'catalog' },
  { partner: p_img_5, brand_name: 'Bergauf', img_type: 'catalog' },
  { partner: p_img_6, brand_name: 'Основит', img_type: 'catalog' },
  { partner: p_img_7, brand_name: 'Аквабарьер', img_type: 'catalog' },
  { partner: p_img_8, brand_name: 'Гипсокартон и Гвл', img_type: 'catalog' },
  { partner: p_img_9, brand_name: 'Профиль', img_type: 'catalog' },
  { partner: p_img_10, brand_name: 'Новоблок', img_type: 'catalog' },
  { partner: p_img_11, brand_name: 'Litokol', img_type: 'catalog' },
  { partner: p_img_12, brand_name: 'URSA', img_type: 'catalog' },
  { partner: p_img_13, brand_name: 'URSA', img_type: 'info' },
  { partner: p_img_14, brand_name: 'URSA', img_type: 'info' },
];

export default arrPartnersImg;

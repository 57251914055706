import React from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { getItem } from '../../redux/slices/itemSlice';
import { addItem } from '../../redux/slices/cartSlice';

const Item = ({ value }) => {
  const dispatch = useDispatch();
  //console.log(value);
  const cartItem = useSelector((state) =>
    state.cart.items.find((obj) => obj.id === value.element.id),
  );
  const addedCount = cartItem ? cartItem.count : 0;
  const onClickAdd = (el) => {
    const iV = JSON.parse(el.item_variants);
    const itemVariant = iV == false ? 'default' : iV[0].variant;
    const itemPrice = iV == false ? el.item_price : iV[0].price;
    //console.log(iV);
    const item = {
      id: el.id,
      variant: itemVariant,
      title: el.item_name,
      imageUrl: el.item_main_photo,

      price: itemPrice,
    };
    dispatch(addItem(item));
  };
  return (
    <div className={styles.root}>
      <Link
        onClick={() => {
          localStorage.setItem('currentItem', JSON.stringify(value.element));
          dispatch(getItem(value.element));
        }}
        className={styles.link_to}
        style={{ textDecoration: 'none' }}
        to={'item'}></Link>
      <div className={styles.img_part_div}>
        <img src={value.item_img} />
      </div>
      <div className={styles.info_part_div}>
        <div className={styles.item_name_div}>
          <p>{value.name}</p>
        </div>
        <div className={styles.item_price_div}>
          <div>{value.price} ₽</div>
          <div>
            <div className={styles.add_to_cart_btn}>
              <div>смотреть</div>
              <div></div>
              {addedCount > '' && (
                <div>
                  {
                    //addedCount//
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;

//  < div onClick={() => onClickAdd(value.element)} className={styles.add_to_cart_btn}>

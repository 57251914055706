import React from 'react';
import PartnersBlock from '../components/partners_block';
import Item from '../components/item';
import LineItemsPlace from '../components/line_items_place';
import MineSlider from '../components/mine_slider';

const MainScreen = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <MineSlider />
      <PartnersBlock />
      <LineItemsPlace />
    </div>
  );
};

export default MainScreen;

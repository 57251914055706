import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  item: '',
};

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    getItem(state, action) {
      state.item = action.payload;
    },
  },
});

export const { getItem } = itemSlice.actions;

export default itemSlice.reducer;

import styles from './index.module.scss';
import Item from '../item';
import React from 'react';
import debounce from 'lodash.debounce';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeValue, showItems } from '../../redux/slices/filterSlice';
import { sortCatalog } from '../items_catalog_place/sort_catalog_func';

const LineItems = ({ value }) => {
  const [canScrollLeft, setCanScrollLeft] = React.useState(false);
  const [canScrollRight, setCanScrollRight] = React.useState(false);
  const listRef = React.useRef(null);

  const checkForScrollPosition = () => {
    const { current } = listRef;
    if (current) {
      const { scrollLeft, scrollWidth, clientWidth } = current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft !== scrollWidth - clientWidth);
    }
  };

  const debounceCheckForScrollPosition = debounce(checkForScrollPosition, 200);

  const scrollContainerBy = (distance) => {
    //   alert('dd');
    listRef.current?.scrollBy({ left: distance, behavior: 'smooth' });
  };
  React.useEffect(() => {
    const { current } = listRef;
    checkForScrollPosition();
    current?.addEventListener('scroll', debounceCheckForScrollPosition);

    return () => {
      current?.removeEventListener('scroll', debounceCheckForScrollPosition);
      debounceCheckForScrollPosition.cancel();
    };
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////
  const navigate = useNavigate();
  const items = useSelector((state) => state.data.data.items);
  const dispatch = useDispatch();
  let active_items = JSON.parse(value.row_items);
  //console.log(value);
  return (
    <div className={styles.root}>
      <div className={styles.title_LI}>
        <div></div>
        <div
          onClick={() => {
            scrollContainerBy(-400);
          }}
          className={styles.arrow_left}>
          <svg viewBox='0 0 100 100'>
            <path d='M70.787,27.267v8.294c0,0.571-0.305,1.1-0.8,1.385L49.776,48.615c-1.066,0.616-1.066,2.155,0,2.771l20.212,11.669   c0.495,0.286,0.8,0.814,0.8,1.385v8.294c0,1.231-1.333,2.001-2.399,1.385L29.013,51.385c-1.066-0.616-1.066-2.155,0-2.771   l39.375-22.733C69.454,25.266,70.787,26.035,70.787,27.267z' />
            <path d='M69.987,63.055l-2.376-1.372c-0.013,0.043-0.023,0.09-0.032,0.136l0.409,0.236c0.495,0.286,0.8,0.814,0.8,1.385v8.294   c0,1.008-0.894,1.693-1.803,1.575l1.404,0.81c1.066,0.616,2.399-0.154,2.399-1.385V64.44C70.787,63.868,70.482,63.34,69.987,63.055   z' />
            <path d='M49.776,48.615l20.212-11.669c0.495-0.286,0.8-0.814,0.8-1.385v-8.294c0-1.115-1.092-1.84-2.091-1.512   c0.054,0.16,0.091,0.328,0.091,0.512v8.294c0,0.571-0.305,1.1-0.8,1.385L47.776,47.615c-0.078,0.052-0.745,0.516-0.8,1.385   c-0.057,0.911,0.609,1.467,0.673,1.518c0.639,0.443,1.277,0.885,1.916,1.328c-0.032-0.112-0.07-0.312,0.019-0.51   c0.011-0.025,0.024-0.049,0.037-0.07C48.725,50.593,48.768,49.196,49.776,48.615z' />
          </svg>
        </div>
        <div
          onClick={() => {
            scrollContainerBy(400);
          }}
          className={styles.arrow_right}>
          <svg className={styles.arrow_svg} viewBox='0 0 100 100'>
            <path d='M28.213,27.267v8.294c0,0.571,0.305,1.1,0.8,1.385l20.212,11.669c1.066,0.616,1.066,2.155,0,2.771L29.013,63.055   c-0.495,0.286-0.8,0.814-0.8,1.385v8.294c0,1.231,1.333,2.001,2.399,1.385l39.375-22.733c1.066-0.616,1.066-2.155,0-2.771   L30.612,25.881C29.546,25.266,28.213,26.035,28.213,27.267z' />
            <path d='M69.987,48.615L30.612,25.881c-0.193-0.112-0.395-0.164-0.597-0.19l37.972,21.923c1.066,0.616,1.066,2.155,0,2.771   L28.612,73.119c-0.1,0.058-0.205,0.092-0.308,0.126c0.308,0.914,1.401,1.398,2.308,0.874l39.375-22.733   C71.054,50.77,71.054,49.23,69.987,48.615z' />
          </svg>
        </div>
        <div>
          <p>{value.row_name}</p>

          <div
            onClick={() => {
              navigate('catalog');
              if (value.row_category !== 'no_link') {
                dispatch(changeValue(value.row_category));
                dispatch(showItems(sortCatalog(items, value.row_category)));
              } else {
                dispatch(changeValue('все'));
                dispatch(showItems(sortCatalog(items, 'все')));
              }
            }}
            className={styles.all_items_btn}>
            смотреть все
          </div>
        </div>
      </div>
      <div className={styles.items_line_group} ref={listRef}>
        {active_items.map((el, i) => {
          // console.log(el);
          let itemData = '';
          if (el.item_id_in_row !== '') {
            items.forEach((elem) => {
              //   console.log(el.item_id_in_row);
              if (elem.id === el.item_id_in_row * 1) {
                itemData = elem;
              }
            });
          }
          //console.log(itemData);
          let variants = itemData.item_variants === 'false' ? '' : itemData.item_variants;
          let a = variants !== '' && variants !== undefined ? JSON.parse(variants) : '';
          let start_price = itemData.item_price === 'false' ? a[0].price : itemData.item_price;

          return el.item_id_in_row === '' ? (
            ''
          ) : (
            <Item
              key={i}
              value={{
                element: itemData,
                name: itemData.item_name,
                price: start_price,
                item_img: itemData.item_main_photo
                  ? 'https://glavoblsnab.store' + itemData.item_main_photo
                  : '',
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LineItems;

import MainScreen from '../../pages/main';
import CatalogScreen from '../../pages/catalog';
import CartScreen from '../../pages/cart';
import ItemScreen from '../../pages/item';
import NotFound from '../../pages/notFound';
import PayInfo from '../../pages/pay_info';
import FAQ from '../../pages/faq';
import AboutUs from '../../pages/about_us';
import OrderConfirmed from '../../pages/order_confirmed';

import { Routes, Route } from 'react-router-dom';

import styles from './index.module.scss';

function Main() {
  const wih = window.innerHeight;
  return (
    <div className={styles.root} style={{ minHeight: wih - 200 }}>
      <Routes>
        <Route path='/' element={<MainScreen />} />
        <Route path='about_us' element={<AboutUs />} />
        <Route path='catalog' element={<CatalogScreen />} />
        <Route path='pay_info' element={<PayInfo />} />
        <Route path='faq_page' element={<FAQ />} />
        <Route path='cart' element={<CartScreen />} />
        <Route path='catalog/item' element={<ItemScreen />} />
        <Route path='item' element={<ItemScreen />} />
        <Route path='cart/order_confirmed' element={<OrderConfirmed />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Main;

import React from 'react';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { addItem, removeItem } from '../../redux/slices/cartSlice';

const ItemPagePrice = ({ itemData }) => {
  const dispatch = useDispatch();
  const variants =
    !itemData.item_variants || itemData.item_variants == 'false'
      ? 'false'
      : JSON.parse(itemData.item_variants);
  const difference = variants == 'false' ? 'false' : itemData.item_difference;
  const price = variants == 'false' ? itemData.item_price : variants[0].price;
  // console.log(variants);

  const [cnt, setCnt] = React.useState(1);
  const [currentVariant, setCurrentVariant] = React.useState(0);
  const [currentPrice, setCurrentPrice] = React.useState(price);
  const [varName, setVarName] = React.useState(
    variants != 'false' ? variants[0].variant : 'default',
  );

  React.useEffect(() => {
    const el = document.getElementById('input_cnt');
    el.value = cnt;
  }, [cnt]);

  const cartItem = useSelector((state) =>
    state.cart.items.find((obj) => obj.id === itemData.id && obj.variant === varName),
  );
  // console.log(currentVariant);
  // console.log(cartItem);
  const addedCount = cartItem ? cartItem.count : 0;

  const onClickAdd = (el, c) => {
    const item = {
      id: el.id,
      variant: varName,
      title: el.item_name,
      imageUrl: el.item_main_photo,
      price: currentPrice,
    };
    for (let i = 0; i < c; i++) {
      dispatch(addItem(item));
    }
  };

  const onClickRemove = (el) => {
    const item = {
      id: el.id,
      variant: varName,
      title: el.item_name,
      imageUrl: el.item_main_photo,

      price: currentPrice,
    };
    dispatch(removeItem(item));
  };

  //console.log(varName);
  return (
    <div className={styles.root}>
      {variants != 'false' && (
        <div className={styles.item_variants}>
          <div>
            <p>{difference}</p>
          </div>
          <div>
            {variants != 'false' &&
              variants.map((el, i) => {
                return (
                  <p
                    onClick={() => {
                      setCurrentPrice(el.price);
                      setCurrentVariant(i);
                      setVarName(el.variant);
                      setCnt(1);
                    }}
                    className={i == currentVariant ? styles.variant_on : styles.variant_off}
                    key={i}>
                    {el.variant}
                  </p>
                );
              })}
          </div>
        </div>
      )}
      <div className={styles.price_div}>
        <p className={styles.price}>{'цена : ' + currentPrice * cnt + ' ₽'}</p>
        <div className={styles.count_div}>
          <div onClick={() => /* onClickAdd(itemData)*/ setCnt(cnt + 1)} className={styles.plus}>
            +
          </div>
          <input
            type='number'
            id='input_cnt'
            defaultValue={cnt}
            onBlur={() => {
              let el = document.getElementById('input_cnt');
              if (el.value == '' || el.value == 0) {
                el.value = 1;
                setCnt(1 * 1);
              }
            }}
            className={styles.count}
            onChange={(num) => {
              if (num.target.value === '') {
                setCnt(0 * 1);
              } else if (num.target.value > 99999) {
                num.target.value = 99999;
              }
              setCnt(num.target.value * 1);
            }}
          />
          <div
            onClick={() => /*onClickRemove(itemData)*/ cnt != 1 && setCnt(cnt - 1)}
            className={styles.minus}>
            -
          </div>
        </div>
        <div onClick={() => onClickAdd(itemData, cnt)} className={styles.to_cart_btn}>
          Добавить
        </div>
      </div>
    </div>
  );
};

export default ItemPagePrice;

import styles from './index.module.scss';
import ItemPageInfo from '../item_page_info';
import ItemPageSlider from '../item_page_slider';
import ItemPagePrice from '../item_page_price';
import { useSelector, useDispatch } from 'react-redux';

const ItemPagePlace = () => {
  const currentItem = !localStorage.getItem('currentItem')
    ? ''
    : JSON.parse(localStorage.getItem('currentItem'));
  const itemData = useSelector((state) => state.item.item);

  // console.log(currentItem);
  return (
    <div className={styles.root}>
      <div className={styles.ipp_title}>
        <div>
          <p>{!itemData.item_name ? currentItem.item_name : itemData.item_name}</p>
        </div>
        <div className={styles.back_red}></div>
        <div></div>
      </div>
      <ItemPageSlider itemData={!itemData ? currentItem : itemData} />
      <ItemPagePrice itemData={!itemData ? currentItem : itemData} />
      <ItemPageInfo itemData={!itemData ? currentItem : itemData} />
    </div>
  );
};

export default ItemPagePlace;

import styles from './index.module.scss';

const OnasA = () => {
  return (
    <div className={styles.root}>
      <div className={styles.OnasA_div}>
        <h2>РАБОТАЕМ ДЛЯ ВАС БОЛЕЕ 20-ТИ ЛЕТ</h2>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_1.png')} />
          </div>
          <div className={styles.info_div}>
            <p>Наличие склада с постоянным пополнением товарного запаса</p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_2.png')} />
          </div>
          <div className={styles.info_div}>
            <p>К каждому партнеру имеем индивидуальный подход</p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_3.png')} />
          </div>
          <div className={styles.info_div}>
            <p>Мы предоставляем широкий ассортимент строительных материалов по всей России</p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_4.png')} />
          </div>
          <div className={styles.info_div}>
            <ul>
              Наша организация осуществляет свою деятельность по следующим направлениям:
              <li>
                Оптовые и розничные продажи строительных и отделочных материалов со
                складских комплексов в Московском регионе;
              </li>
              <li>Доставка строительных и отделочных материалов по всей России;</li>
            </ul>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_5.png')} />
          </div>
          <div className={styles.info_div}>
            <ul>
              Продажи происходят способами:
              <li>Online - в интернет-магазине (оптом, мелким оптом, в розницу, под заказ);</li>
              <li>Оптом со складов Москвы и области;</li>
              <li>Под заказ - прямые поставки с заводов.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnasA;

import styles from './index.module.scss';

const OnasB = () => {
  return (
    <div className={styles.root}>
      <div className={styles.OnasB_div}>
        <h2>ПРЕИМУЩЕСТВА</h2>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_6.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Мы имеем многочисленный опыт поставок различных строительных материалов на объекты,
              как в Москве, так и в регионах
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_7.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Используем только качественные материалы от проверенных поставщиков, что обеспечивает
              хороший результат.
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_8.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              За годы деятельности мы зарекомендовали себя как надежный и ответственный поставщик,
              выполнив множество заказов государственных организаций.
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_9.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              ООО «ГЛАВОБЛСНАБ» является многопрофильной организацией, поэтому в штате работает
              множество различных специалистов.
            </p>
          </div>
        </div>
        <div className={styles.img_info_div}>
          <div className={styles.img_div}>
            <img src={require('../../assets/o_nas_imgs/o_nas_10.png')} />
          </div>
          <div className={styles.info_div}>
            <p>
              Одним из главных преимуществ нашей организации является возможность кредитования наших
              партнеров и наличие прямых контрактов с заводами и производителями.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnasB;

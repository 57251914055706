import React from 'react';
import HeaderPage from '../components/header_page';
import DostavkaInfo from '../components/dostavka_info';
import OnasC from '../components/o_nas_c';
const PayInfo = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <HeaderPage value={'КОНТАКТЫ'} />
      <DostavkaInfo />
      <OnasC />
    </div>
  );
};

export default PayInfo;

import React from 'react';
import styles from './index.module.scss';
import arrPartnersImg from '../../assets/partners_img/arr_partners_img';
import { useNavigate } from 'react-router-dom';
import { sortCatalog } from '../items_catalog_place/sort_catalog_func';
import { useSelector, useDispatch } from 'react-redux';
import { cat_search, changeValue, showItems, searchRequest } from '../../redux/slices/filterSlice';
const PartnersBlock = () => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();
  const [partnerInfo, setPartnerInfo] = React.useState(false);

  return (
    <div className={styles.root}>
      {partnerInfo && (
        <div className={styles.partner_info}>
          <div className={styles.partner_info_div}>
            <input
              className={styles.close_partner_info}
              type='button'
              value='X'
              onClick={() => {
                setPartnerInfo(false);
              }}
            />
            <p className={styles.partner_info_text_div}>
              <span className={styles.partner_name}>Алексанян Гор Г.</span> <br />
              <span className={styles.partner_span}>сот.:</span> +7-968-968-29-28 <br />
              <span className={styles.partner_span}> тел./факс:</span> +7(495) 215-24-75 (доб.19){' '}
              <br />
              <span className={styles.partner_span}>E-mail:</span> gor.alexanian@yandex.ru
            </p>
          </div>
        </div>
      )}
      <div className={styles.title_block}>
        <div>
          <p>НАШИ ПАРТНЕРЫ</p>
        </div>

        <div></div>
      </div>
      <div className={styles.img_partners_img}>
        {arrPartnersImg.map((el, i) => {
          return (
            <img
              className={styles.partner_img}
              key={i}
              src={el.partner}
              onClick={() => {
                if (el.img_type === 'catalog') {
                  let itm = sortCatalog(data.items, 'поиск', el.brand_name);
                  //console.log(itm);
                  navigate('catalog');
                  dispatch(cat_search(itm != 0 ? 'поиск :' : 'каталог :'));
                  dispatch(changeValue(itm != 0 ? el.brand_name : 'выберите категорию'));
                  dispatch(showItems(itm));
                  dispatch(searchRequest(el.brand_name));
                } else if (el.img_type === 'info') {
                  setPartnerInfo(true);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PartnersBlock;

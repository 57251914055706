import React from 'react';
import styles from './index.module.scss';
import axios from 'axios';

const CallOrder = () => {
  const [callOrderBtnActive, setCallOrderBtnActive] = React.useState(true);
  const [callOrderPanelActive, setCallOrderPanelActive] = React.useState(false);
  const [confCheck, setConfCheck] = React.useState(true);
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [callOrderState, setCallOrderState] = React.useState('step1');
  return (
    <div className={styles.root}>
      <div className={styles.codiv}>
        <div className={callOrderBtnActive ? styles.call_order_div_min : styles.call_order_div_max}>
          <div
            className={callOrderBtnActive ? styles.call_order_btn_show : styles.call_order_btn_hide}
            onClick={() => {
              setCallOrderPanelActive(!callOrderPanelActive);
              setCallOrderBtnActive(!callOrderBtnActive);
            }}>
            <svg width='60%' viewBox='0 0 12 12'>
              <path
                fill='white'
                d='M2.707 1.293l2 2a1 1 0 010 1.414L4.414 5 7 7.586l.293-.293a1
   1 0 011.414 0l2 2a1 1 0 010 1.414l-.232.232a3.5 3.5 0 01-4.95 0L1.061 6.475a3.5 3.5 0 010-4.95l.232-.232a1 
   1 0 011.414 0zM2 2l-.232.232a2.5 2.5 0 000 3.536l4.464 4.464a2.5 2.5 0 003.536 0L10 10 8 8 7 9 3 5l1-1-2-2zm4.5 
   1a.5.5 0 110-1c1.953 0 3.53 1.58 3.53 3.53a.5.5 0 01-1 0C9.03 4.132 7.9 3 6.5 3zm.23-2a.5.5 0 110-1A5.27 5.27 0 
   0112 5.27a.5.5 0 11-1 0A4.27 4.27 0 006.73 1z'
              />
            </svg>
          </div>
          <div
            className={
              callOrderPanelActive ? styles.call_order_panel_show : styles.call_order_panel_hide
            }>
            <div
              className={styles.close_call_order_panel_btn}
              onClick={() => {
                setCallOrderPanelActive(!callOrderPanelActive);
                setCallOrderBtnActive(!callOrderBtnActive);
                setCallOrderState('step1');
              }}>
              X
            </div>
            {
              //           <p className={styles.call_order_info}>
              //           Для связи с нами : <br />
              //         тел : <span>8(880)8888888</span> <br />
              //       тел : <span>8(880)8888888</span>
              //   </p>
            }
            <div className={styles.call_order}>
              <p className={styles.call_order_info_text}>Заказать звонок</p>
              {callOrderState === 'step1' && (
                <p className={styles.call_order_info_text2}>
                  Для получения консультации по нашей продукции и/или помощи в оформлении заказа,
                  оставьте свои контакты, и мы свяжемся с вами в ближайшее время.
                </p>
              )}
              {
                // <div>
                // <label className={styles.call_order_info_text}>Фио : </label>
                // <input className={styles.call_order_info_input} />
                // </div>
              }
              {callOrderState === 'step1' && (
                <div className={styles.tel_div}>
                  <label className={styles.call_order_info_text2}>Имя : </label>
                  <input
                    className={styles.call_order_fio_input}
                    onChange={(text) => {
                      setName(text.target.value);
                    }}
                  />
                </div>
              )}
              {callOrderState === 'step1' && (
                <div className={styles.tel_div}>
                  <label className={styles.call_order_info_text2}>Тел : </label>
                  <input
                    type='number'
                    className={styles.call_order_number_input}
                    onChange={(text) => {
                      setPhone(text.target.value);
                    }}
                  />
                </div>
              )}
              {callOrderState === 'step1' && (
                <input
                  type='button'
                  value='отправить'
                  className={styles.call_order_info_btn}
                  onClick={() => {
                    if (confCheck) {
                      axios
                        .post(
                          'https://glavoblsnab.store/server/_index_server.php',
                          {
                            type_gos: 'send_call_order',
                            name: name,
                            phone: phone,
                          },
                          {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          },
                        )
                        .then(({ data }) => {
                          console.log(data.answer);
                          if (data.answer !== undefined && data.answer === 'call_order_confirm') {
                            setCallOrderState('step2');
                            setName('');
                            setPhone('');
                          }
                        });
                    } else;
                  }}
                />
              )}
              {callOrderState === 'step1' && (
                <div className={styles.call_order_info_confirm}>
                  {confCheck ? (
                    <label>даю согласие на обработку предоставленных мной данных</label>
                  ) : (
                    <label>для отправки заявки необходимо дать согласие на обработку данных</label>
                  )}
                  <input
                    type='checkbox'
                    checked={confCheck}
                    onChange={() => setConfCheck(!confCheck)}
                  />
                </div>
              )}
              {callOrderState === 'step2' && (
                <div className={styles.call_order_conf_div}>
                  <p className={styles.call_order_info_text3}>Заявка принята!</p>
                  <p className={styles.call_order_info_text3}>
                    Мы свяжемся с вами в ближайшее время.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallOrder;

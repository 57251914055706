import React from 'react';
import HeaderPage from '../components/header_page';
import OrderInfo from '../components/order_info';

const OrderConfirmed = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <HeaderPage value={'ЗАКАЗ ПРИНЯТ'} />
      <OrderInfo />
    </div>
  );
};

export default OrderConfirmed;

import styles from './index.module.scss';
import LineItems from '../line_items';
import { useSelector } from 'react-redux';

const LineItemsPlace = () => {
  const data = useSelector((state) => state.data.data);
  console.log(data.lines);
  const active_row_line_list = data.length !== 0 ? JSON.parse(data.options[0].row_line_list) : '';
  //if (active_row_line_list !== '' && active_row_line_list !== null) console.log('qwer');

  return (
    <div className={styles.root}>
      {active_row_line_list !== '' && active_row_line_list !== null && (
        <div className={styles.title_LIP}>
          <div>
            <p>НАША ПРОДУКЦИЯ</p>
          </div>

          <div></div>
        </div>
      )}
      <div className={styles.LIP_place}>
        {active_row_line_list !== '' &&
          active_row_line_list !== null &&
          active_row_line_list.map((el, i) => {
            let values;

            data.lines.forEach((elem) => {
              if (elem.row_name === el) {
                values = elem;
                // console.log(elem);
              }
            });

            return <LineItems key={i} value={values} />;
          })}
      </div>
    </div>
  );
};

export default LineItemsPlace;

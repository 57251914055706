import styles from './index.module.scss';
import Item from '../item';
import { useSelector, useDispatch } from 'react-redux';
import { changeValue, showItems, cat_search } from '../../redux/slices/filterSlice';

const ItemsCatalogPlace = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const showItems = useSelector((state) => state.filter.catalog_items);
  const filter = useSelector((state) => state.filter);

  if (showItems[0] === 'empty');
  return (
    <div className={styles.root}>
      {data.length != 0 &&
        showItems != 0 &&
        showItems == 'empty' &&
        data.items.map((el, i) => {
          const variants = el.item_variants == 'false' ? '' : JSON.parse(el.item_variants);
          //console.log(variants);
          const start_price = el.item_price == 'false' ? variants[0].price : el.item_price;
          return (
            <Item
              key={i}
              value={{
                element: el,
                name: el.item_name,
                price: start_price,
                item_img: el.item_main_photo
                  ? 'https://glavoblsnab.store' + el.item_main_photo
                  : '',
              }}
            />
          );
        })}
      {showItems != 0 &&
        showItems != 'empty' &&
        showItems != undefined &&
        showItems.map((el, i) => {
          const variants = el.item_variants == 'false' ? '' : JSON.parse(el.item_variants);
          const start_price = el.item_price == 'false' ? variants[0].price : el.item_price;
          return (
            <Item
              key={i}
              value={{
                element: el,
                name: el.item_name,
                price: start_price,
                item_img: el.item_main_photo
                  ? 'https://glavoblsnab.store' + el.item_main_photo
                  : '',
              }}
            />
          );
        })}
      {showItems == 0 && (
        <div className={styles.wrong_search}>
          <p>По запросу "{filter.searchRequest}" ничего не найдено</p>
        </div>
      )}
    </div>
  );
};

export default ItemsCatalogPlace;

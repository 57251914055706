import logo_img from '../../assets/gos_logo.png';
import styles from './index.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { sortCatalog } from '../items_catalog_place/sort_catalog_func';
import { useSelector, useDispatch } from 'react-redux';
import { changeValue, showItems, cat_search, searchRequest } from '../../redux/slices/filterSlice';
import { useNavigate } from 'react-router-dom';
import { addItem } from '../../redux/slices/cartSlice';

const Header = () => {
  const { items, totalPrice } = useSelector((state) => state.cart);
  React.useEffect(() => {
    const storageItems =
      localStorage.getItem('items') && localStorage.getItem('items').length > 5
        ? JSON.parse(localStorage.getItem('items'))
        : 'empty';
    typeof storageItems == 'object' &&
      storageItems.map((el) => {
        //console.log(el.count + ' fff');
        for (let i = 0; i < el.count; i++) {
          dispatch(addItem(el));
        }
      });
    //dispatch(addItem(storageItems));
  }, []);

  const navigate = useNavigate();
  function handleKeyPress(key) {
    if (key.key == 'Enter') {
      let itm = sortCatalog(data.items, 'поиск', searchInputValue);

      navigate('catalog');
      dispatch(cat_search(itm != 0 ? 'поиск :' : 'каталог :'));
      dispatch(changeValue(itm != 0 ? searchInputValue : 'выберите категорию'));
      dispatch(showItems(itm));
      dispatch(searchRequest(searchInputValue));
      setSearchInputValue('');
      setNavMenuState(styles.show_hide_header_panel);
    }
    //console.log(key);
  }
  //

  //const cat_search = useSelector((state) => state.filter.cat_search);
  const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();
  const [navMenuState, setNavMenuState] = React.useState(styles.show_hide_header_panel);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [currentPage, setCurrentPage] = React.useState('');
  const [searchInputValue, setSearchInputValue] = React.useState('');

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    if (width >= 1000) {
      setNavMenuState(styles.show_hide_header_panel);
    }
  };
  React.useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });
  React.useEffect(() => {
    const json = JSON.stringify(items);
    localStorage.setItem('items', json);
    // console.log(json);
  }, [items]);

  return (
    <div className={styles.root}>
      <div className={styles.root2}>
        <div
          className={styles.show_hide_menu_btn_div}
          onClick={() => {
            if (navMenuState == styles.show_header_panel) {
              setNavMenuState(styles.hide_header_panel);
            } else setNavMenuState(styles.show_header_panel);
          }}>
          <div>
            <svg height='100%' viewBox='0 0 28 28' width='100%'>
              <path
                d='M8 7.29805C8 6.85622 8.35817 6.49805 8.8 6.49805L25.2 6.49805C25.6418 6.49805 26 6.85622 26 7.29805L26 7.69805C26 8.13987 25.6418 8.49805 25.2 8.49805L8.8 8.49805C8.35817 8.49805 8 8.13987 8 7.69805L8 7.29805Z'
                fill='white'
              />
              <path
                d='M8 21.298C8 20.8562 8.35817 20.498 8.8 20.498L25.2 20.498C25.6418 20.498 26 20.8562 26 21.298L26 21.698C26 22.1399 25.6418 22.498 25.2 22.498L8.8 22.498C8.35817 22.498 8 22.1399 8 21.698L8 21.298Z'
                fill='white'
              />
              <path
                d='M8 14.298C8 13.8562 8.35817 13.498 8.8 13.498L25.2 13.498C25.6418 13.498 26 13.8562 26 14.298L26 14.698C26 15.1399 25.6418 15.498 25.2 15.498L8.8 15.498C8.35817 15.498 8 15.1399 8 14.698L8 14.298Z'
                fill='white'
              />
              <path
                d='M5 7.5C5 8.32843 4.32843 9 3.5 9C2.67157 9 2 8.32843 2 7.5C2 6.67157 2.67157 6 3.5 6C4.32843 6 5 6.67157 5 7.5Z'
                fill='white'
              />
              <path
                d='M5 21.498C5 22.3265 4.32843 22.998 3.5 22.998C2.67157 22.998 2 22.3265 2 21.498C2 20.6696 2.67157 19.998 3.5 19.998C4.32843 19.998 5 20.6696 5 21.498Z'
                fill='white'
              />
              <path
                d='M5 14.498C5 15.3265 4.32843 15.998 3.5 15.998C2.67157 15.998 2 15.3265 2 14.498C2 13.6696 2.67157 12.998 3.5 12.998C4.32843 12.998 5 13.6696 5 14.498Z'
                fill='white'
              />
            </svg>
          </div>
        </div>
        {
          ///////////////////////////////////////////////////////////////////
        }
        <Link to='/'>
          <div
            className={styles.full_logo_div}
            onClick={() => {
              setCurrentPage('/');
              setNavMenuState(styles.show_hide_header_panel);
            }}>
            <svg className={styles.r_logo_wall} viewBox='0 0 64 64'>
              <path
                d='M54 19L44 19L44 12C44 11.4477 43.5523 11 43 11L15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13L20 13L20 19L10 19C9.44772 19 9 19.4477 9 20L9 28C9 28.5523 9.44772 29 10 29L20 29L20 35L10 35C9.44772 35 9 35.4477 9 36L9 44C9 44.5523 9.44772 45 10 45L20 45L20 51L14.88 51C14.3277 51 13.88 51.4477 13.88 52C13.88 52.5523 14.3277 53 14.88 53L43 53C43.5523 53 44 52.5523 44 52L44 45L54 45C54.5523 45 55 44.5523 55 44L55 36C55 35.4477 54.5523 35 54 35L44 35L44 29L54 29C54.5523 29 55 28.5523 55 28L55 20C55 19.4477 54.5523 19 54 19ZM22 13L42 13L42 19L22 19L22 13ZM11 27L11 21L31 21L31 27L11 27ZM11 43L11 37L31 37L31 43L11 43ZM42 51L22 51L22 45L42 45L42 51ZM53 43L33 43L33 37L53 37L53 43ZM42 35L22 35L22 29L42 29L42 35ZM53 27L33 27L33 21L53 21L53 27Z'
                fill='rgb(166, 165, 165)'
              />
            </svg>
            <img src={logo_img} />

            <svg className={styles.l_logo_wall} viewBox='0 0 64 64'>
              <path
                d='M54 19L44 19L44 12C44 11.4477 43.5523 11 43 11L15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13L20 13L20 19L10 19C9.44772 19 9 19.4477 9 20L9 28C9 28.5523 9.44772 29 10 29L20 29L20 35L10 35C9.44772 35 9 35.4477 9 36L9 44C9 44.5523 9.44772 45 10 45L20 45L20 51L14.88 51C14.3277 51 13.88 51.4477 13.88 52C13.88 52.5523 14.3277 53 14.88 53L43 53C43.5523 53 44 52.5523 44 52L44 45L54 45C54.5523 45 55 44.5523 55 44L55 36C55 35.4477 54.5523 35 54 35L44 35L44 29L54 29C54.5523 29 55 28.5523 55 28L55 20C55 19.4477 54.5523 19 54 19ZM22 13L42 13L42 19L22 19L22 13ZM11 27L11 21L31 21L31 27L11 27ZM11 43L11 37L31 37L31 43L11 43ZM42 51L22 51L22 45L42 45L42 51ZM53 43L33 43L33 37L53 37L53 43ZM42 35L22 35L22 29L42 29L42 35ZM53 27L33 27L33 21L53 21L53 27Z'
                fill='rgb(166, 165, 165)'
              />
            </svg>
          </div>
        </Link>
        {
          /////////////////////////////////////////////////////////////////// navMenuState == 'a' ? styles.show_hide_header_panel : styles.show_header_panel
        }
        <div className={navMenuState}>
          <div className={styles.search_input_div}>
            <svg className={styles.search_wall_l} viewBox='0 0 64 64'>
              <path
                d='M36.5 21C37.0523 21 37.5 20.5523 37.5 20L37.5 12C37.5 11.4477 37.0523 11 36.5 11L27.5 11C26.9477 11 26.5 11.4477 26.5 12L26.5 20C26.5 20.5523 26.9477 21 27.5 21L33.5 21L33.5 27L27.5 27C26.9477 27 26.5 27.4477 26.5 28L26.5 36C26.5 36.5523 26.9477 37 27.5 37L33.5 37L33.5 43L27.5 43C26.9477 43 26.5 43.4477 26.5 44L26.5 52C26.5 52.5523 26.9477 53 27.5 53L36.5 53C37.0523 53 37.5 52.5523 37.5 52L37.5 44C37.5 43.4477 37.0523 43 36.5 43L35.5 43L35.5 37L36.5 37C37.0523 37 37.5 36.5523 37.5 36L37.5 28C37.5 27.4477 37.0523 27 36.5 27L35.5 27L35.5 21L36.5 21ZM35.5 51L28.5 51L28.5 45L35.5 45L35.5 51ZM34.5 35L28.5 35L28.5 29L35.5 29L35.5 35L34.5 35ZM28.5 13L35.5 13L35.5 19L28.5 19L28.5 13Z'
                fill='rgb(166, 165, 165)'
              />
            </svg>
            <svg className={styles.search_icon} viewBox='0 0 32 32'>
              <path
                d='M13 2C6.935 2 2 6.935 2 13C2 19.065 6.935 24 13 24C19.065 24 24 19.065 24 13C24 6.935 19.065 2 13 2ZM13 22C8.038 22 4 17.963 4 13C4 8.038 8.038 4 13 4C17.963 4 22 8.038 22 13C22 17.963 17.963 22 13 22Z'
                fill='grey'
              />
              <path
                d='M29.707 28.293L23.706 22.293C23.315 21.902 22.683 21.902 22.292 22.293C21.901 22.684 21.901 23.316 22.292 23.707L28.293 29.707C28.488 29.902 28.744 30 29 30C29.256 30 29.512 29.902 29.707 29.707C30.098 29.316 30.098 28.684 29.707 28.293Z'
                fill='grey'
              />
            </svg>

            <input
              onKeyDown={(e) => handleKeyPress(e)}
              onChange={(text) => {
                setSearchInputValue(text.target.value);

                // console.log(text.target.value);
              }}
              value={searchInputValue}
              placeholder='Поиск...'
              type='text'
            />

            <Link to={'catalog'} className={searchInputValue == '' ? styles.disableLink : ''}>
              <svg
                onClick={() => {
                  // console.log(data.items);
                  let itm = sortCatalog(data.items, 'поиск', searchInputValue);

                  navigate('catalog');
                  dispatch(cat_search(itm != 0 ? 'поиск :' : 'каталог :'));
                  dispatch(changeValue(itm != 0 ? searchInputValue : 'выберите категорию'));
                  dispatch(showItems(itm));
                  dispatch(searchRequest(searchInputValue));
                  setSearchInputValue('');
                  setNavMenuState(styles.show_hide_header_panel);
                }}
                className={
                  searchInputValue != ''
                    ? styles.search_enter_arrow_active
                    : styles.search_enter_arrow
                }
                viewBox='0 0 100 100'>
                <path d='M28.213,27.267v8.294c0,0.571,0.305,1.1,0.8,1.385l20.212,11.669c1.066,0.616,1.066,2.155,0,2.771L29.013,63.055   c-0.495,0.286-0.8,0.814-0.8,1.385v8.294c0,1.231,1.333,2.001,2.399,1.385l39.375-22.733c1.066-0.616,1.066-2.155,0-2.771   L30.612,25.881C29.546,25.266,28.213,26.035,28.213,27.267z' />
                <path d='M69.987,48.615L30.612,25.881c-0.193-0.112-0.395-0.164-0.597-0.19l37.972,21.923c1.066,0.616,1.066,2.155,0,2.771   L28.612,73.119c-0.1,0.058-0.205,0.092-0.308,0.126c0.308,0.914,1.401,1.398,2.308,0.874l39.375-22.733   C71.054,50.77,71.054,49.23,69.987,48.615z' />
              </svg>
            </Link>
            <svg className={styles.search_wall_r} viewBox='0 0 64 64'>
              <path
                d='M54 19L44 19L44 12C44 11.4477 43.5523 11 43 11L15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13L20 13L20 19L10 19C9.44772 19 9 19.4477 9 20L9 28C9 28.5523 9.44772 29 10 29L20 29L20 35L10 35C9.44772 35 9 35.4477 9 36L9 44C9 44.5523 9.44772 45 10 45L20 45L20 51L14.88 51C14.3277 51 13.88 51.4477 13.88 52C13.88 52.5523 14.3277 53 14.88 53L43 53C43.5523 53 44 52.5523 44 52L44 45L54 45C54.5523 45 55 44.5523 55 44L55 36C55 35.4477 54.5523 35 54 35L44 35L44 29L54 29C54.5523 29 55 28.5523 55 28L55 20C55 19.4477 54.5523 19 54 19ZM22 13L42 13L42 19L22 19L22 13ZM11 27L11 21L31 21L31 27L11 27ZM11 43L11 37L31 37L31 43L11 43ZM42 51L22 51L22 45L42 45L42 51ZM53 43L33 43L33 37L53 37L53 43ZM42 35L22 35L22 29L42 29L42 35ZM53 27L33 27L33 21L53 21L53 27Z'
                fill='rgb(166, 165, 165)'
              />
            </svg>
          </div>
          {
            ///////////////////////////////////////////////////////////////////
          }
          <ul>
            <Link to='about_us' style={{ textDecoration: 'none' }}>
              <li
                style={
                  currentPage == 'about_us'
                    ? { color: 'rgb(219, 41, 41)' }
                    : { color: 'rgb(61, 61, 61)' }
                }
                onClick={() => {
                  setCurrentPage('about_us');
                  setNavMenuState(styles.show_hide_header_panel);
                }}>
                о нас
              </li>
            </Link>
            <li className={styles.palka}>|</li>
            <Link to='catalog' style={{ textDecoration: 'none' }}>
              <li
                style={
                  currentPage == 'catalog'
                    ? { color: 'rgb(219, 41, 41)' }
                    : { color: 'rgb(61, 61, 61)' }
                }
                onClick={() => {
                  setCurrentPage('catalog');
                  dispatch(cat_search('категория :'));
                  dispatch(changeValue('все'));
                  dispatch(showItems(sortCatalog(data.items, 'все')));
                  setNavMenuState(styles.show_hide_header_panel);
                }}>
                каталог
              </li>
            </Link>
            <li className={styles.palka}>|</li>
            <Link to='pay_info' style={{ textDecoration: 'none' }}>
              <li
                style={
                  currentPage == 'pay_info'
                    ? { color: 'rgb(219, 41, 41)' }
                    : { color: 'rgb(61, 61, 61)' }
                }
                onClick={() => {
                  setCurrentPage('pay_info');
                  setNavMenuState(styles.show_hide_header_panel);
                }}>
                контакты
              </li>
            </Link>
            {/*   <li className={styles.palka}>|</li>
          <Link to='faq_page' style={{ textDecoration: 'none' }}>
            <li
              translate='no'
              style={
                currentPage == 'faq_page'
                  ? { color: 'rgb(219, 41, 41)' }
                  : { color: 'rgb(61, 61, 61)' }
              }
              onClick={() => {
                setCurrentPage('faq_page');
                setNavMenuState(styles.show_hide_header_panel);
              }}>
              FAQ
            </li>
          </Link>
            */}
          </ul>
        </div>
        {
          ///////////////////////////////////////////////////////////////////
        }
        <Link to='cart' style={{ textDecoration: 'none' }}>
          <div
            className={styles.cart_data_div}
            onClick={() => {
              setCurrentPage('cart');
              setNavMenuState(styles.show_hide_header_panel);
            }}>
            <div>
              <svg height='100%' viewBox='0 0 32 32' width='100%'>
                <path
                  d='M28.41 12.28C28.3135 12.15 28.1619 12.0723 28 12.07L25.07 12.07L25.07 10C25.0647 9.72609 24.8439 9.50532 24.57 9.5L21.64 9.5L21.64 6.57C21.64 6.29386 21.4161 6.07 21.14 6.07L14.29 6.07C14.0139 6.07 13.79 6.29386 13.79 6.57L13.79 9.5L10.86 9.5C10.5839 9.5 10.36 9.72386 10.36 10L10.36 12.07L8.36 12.07L7.06 5.62C7.03857 5.48837 6.96223 5.37204 6.85 5.3L4.28 3.58C4.04804 3.42536 3.73464 3.48804 3.58 3.72C3.42536 3.95196 3.48804 4.26536 3.72 4.42L6.12 6L9.51 23C9.55753 23.2328 9.76237 23.4 10 23.4L24.57 23.4C24.7858 23.3996 24.9781 23.2635 25.05 23.06L28.47 12.77C28.542 12.6081 28.5189 12.4197 28.41 12.28ZM24.07 12.07L18.21 12.07L18.21 10.5L24.07 10.5L24.07 12.07ZM14.79 7.07L20.64 7.07L20.64 9.5L14.79 9.5L14.79 7.07ZM11.36 10.5L17.21 10.5L17.21 12.07L11.36 12.07L11.36 10.5ZM24.21 22.36L10.41 22.36L8.55 13.07L27.31 13.07L24.21 22.36Z'
                  fill='white'
                />
                <path
                  d='M22 24.07C20.9714 24.076 20.0817 24.7878 19.85 25.79L14.72 25.79C14.4659 24.6959 13.4316 23.9665 12.3157 24.0944C11.1998 24.2223 10.3573 25.1668 10.3573 26.29C10.3573 27.4132 11.1998 28.3577 12.3157 28.4856C13.4316 28.6135 14.4659 27.8841 14.72 26.79L19.85 26.79C20.1163 27.913 21.2019 28.6419 22.3422 28.4634C23.4824 28.2849 24.2933 27.259 24.2037 26.1084C24.1141 24.9578 23.1541 24.0698 22 24.07ZM12.57 27.5C11.9416 27.4429 11.4603 26.916 11.4603 26.285C11.4603 25.654 11.9416 25.1271 12.57 25.07C13.0304 25.0282 13.4749 25.2502 13.718 25.6434C13.9611 26.0366 13.9611 26.5334 13.718 26.9266C13.4749 27.3198 13.0304 27.5418 12.57 27.5ZM22 27.5C21.5048 27.504 21.0562 27.2083 20.8648 26.7515C20.6734 26.2947 20.7772 25.7676 21.1274 25.4174C21.4776 25.0672 22.0047 24.9634 22.4615 25.1548C22.9183 25.3462 23.214 25.7948 23.21 26.29C23.21 26.9583 22.6683 27.5 22 27.5Z'
                  fill='white'
                />
                <path
                  d='M11.71 14.64C11.4361 14.6453 11.2153 14.8661 11.21 15.14C11.21 15.4161 11.4339 15.64 11.71 15.64L23.71 15.64C23.9861 15.64 24.21 15.4161 24.21 15.14C24.21 14.8639 23.9861 14.64 23.71 14.64L11.71 14.64Z'
                  fill='white'
                />
                <path
                  d='M22.15 17.25L13.43 17.25L13.43 17.25C13.1539 17.25 12.93 17.4739 12.93 17.75C12.93 18.0261 13.1539 18.25 13.43 18.25L22.14 18.25L22.14 18.25C22.4161 18.25 22.64 18.0261 22.64 17.75C22.64 17.4739 22.4161 17.25 22.14 17.25L22.15 17.25Z'
                  fill='white'
                />
                <path
                  d='M20.29 19.79L15.14 19.79C14.8639 19.79 14.64 20.0139 14.64 20.29C14.6453 20.5639 14.8661 20.7847 15.14 20.79L20.29 20.79C20.5639 20.7847 20.7847 20.5639 20.79 20.29C20.79 20.0139 20.5661 19.79 20.29 19.79Z'
                  fill='white'
                />
              </svg>
              <p>
                {items.reduce((sum, cnt) => {
                  return (sum = cnt.count + sum);
                }, 0)}
              </p>
            </div>

            <div>
              <p>{totalPrice + ' ₽'}</p>
            </div>
          </div>
        </Link>
        <svg className={styles.wall_r} viewBox='0 0 64 64'>
          <path
            d='M54 19L44 19L44 12C44 11.4477 43.5523 11 43 11L15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13L20 13L20 19L10 19C9.44772 19 9 19.4477 9 20L9 28C9 28.5523 9.44772 29 10 29L20 29L20 35L10 35C9.44772 35 9 35.4477 9 36L9 44C9 44.5523 9.44772 45 10 45L20 45L20 51L14.88 51C14.3277 51 13.88 51.4477 13.88 52C13.88 52.5523 14.3277 53 14.88 53L43 53C43.5523 53 44 52.5523 44 52L44 45L54 45C54.5523 45 55 44.5523 55 44L55 36C55 35.4477 54.5523 35 54 35L44 35L44 29L54 29C54.5523 29 55 28.5523 55 28L55 20C55 19.4477 54.5523 19 54 19ZM22 13L42 13L42 19L22 19L22 13ZM11 27L11 21L31 21L31 27L11 27ZM11 43L11 37L31 37L31 43L11 43ZM42 51L22 51L22 45L42 45L42 51ZM53 43L33 43L33 37L53 37L53 43ZM42 35L22 35L22 29L42 29L42 35ZM53 27L33 27L33 21L53 21L53 27Z'
            fill='rgb(166, 165, 165)'
          />
        </svg>
      </div>
    </div>
  );
};

export default Header;

import styles from './index.module.scss';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeValue, showItems, cat_search } from '../../redux/slices/filterSlice';
import { sortCatalog } from '../items_catalog_place/sort_catalog_func';

const CatalogCategoryPanel = () => {
  const data = useSelector((state) => state.data.data);
  const catSearch = useSelector((state) => state.filter.cat_search);
  const [openedCCP, setOpenedCCP] = React.useState(false);
  const filter = useSelector((state) => state.filter.value);
  // console.log(data.category);
  const catalogRef = React.useRef();
  React.useEffect(() => {
    const handleClickOutside = (el) => {
      if (
        el.target.className != 'catbtn' &&
        el.target.className != 'catalog_category_panel_show_hide_CCP__vvah4'
      ) {
        setOpenedCCP(false);
      }
      //  console.log(el);
    };
    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // const showItems = useSelector((state) => state.filter.catalog_items);

  const dispatch = useDispatch();
  return (
    <div className={styles.commonCCP}>
      <div className={styles.CatCatPanel_div}>
        <div className={styles.ccp_cat_div}>
          <div className={styles.ccp_title}>
            <div>
              <p>КАТАЛОГ</p>
            </div>
            <div></div>
          </div>
          <div className={styles.ccp_chose}>
            <p>{catSearch} </p>
            <div
              className='catbtn'
              ref={catalogRef}
              onClick={() => {
                setOpenedCCP(!openedCCP);
              }}>
              {filter}
            </div>
          </div>
        </div>
        <div className={styles.ccp_info_div}>
          <div>
            <p>выберите интересующую вас категорию</p>
          </div>
          <div></div>
        </div>
      </div>
      {openedCCP && (
        <div className={styles.show_hide_CCP}>
          <p
            onClick={() => {
              if (data.category != undefined) {
                dispatch(cat_search('категория :'));
                dispatch(changeValue('все'));
                setOpenedCCP(false);
                dispatch(showItems(sortCatalog(data.items, 'все')));
              }
            }}
            className={filter != 'все' ? styles.category_item : styles.current_category_item}>
            все
          </p>
          {data.category != undefined &&
            data.category.map((el, i) => {
              return (
                <p
                  onClick={() => {
                    dispatch(cat_search('категория :'));
                    dispatch(changeValue(el.item_category));
                    setOpenedCCP(false);
                    dispatch(showItems(sortCatalog(data.items, el.item_category)));
                  }}
                  className={
                    filter != el.item_category ? styles.category_item : styles.current_category_item
                  }
                  key={i}>
                  {el.item_category}
                </p>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default CatalogCategoryPanel;

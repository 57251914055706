import styles from './index.module.scss';

const Footer = () => {
  return (
    <div className={styles.root}>
      <div className={styles.footer_div}>
        <p>
          <b>
            ООО Главоблснаб <br /> Все права защищены
          </b>
        </p>
        <p>
          <b>Контакты отдела продаж:</b> <br /> г. Москва, ул.Салтыковская, д. 8/18 | тел : 8 (495)
          215-24-75 | Email: stroy@stroy2002.ru
          <br /> г. Люберцы, ЖК Гоголь Парк, ул. 8 Марта д.48 | тел : 8 (800) 775-64-52 | Email:
          mshumilin@stroy2002.ru
        </p>
        {
          //  <p>
          //  <b>Отдел продаж Тел/факс: </b>
          //  <br /> 8 (495) 215-24-75 (ул. Салтыковская) <br /> 8 (800) 775-64-52 (ЖК Гоголь Парк)
          //  <br /> Email: stroy@stroy2002.ru
          //</p>
        }
      </div>
    </div>
  );
};

export default Footer;

import styles from './index.module.scss';

const HeaderPage = ({ value }) => {
  return (
    <div className={styles.root}>
      <div className={styles.header_background}>
        <div className={styles.left_romb}></div>
        <label>{value}</label>
        <div className={styles.right_romb}></div>
      </div>
    </div>
  );
};

export default HeaderPage;

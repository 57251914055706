import styles from './index.module.scss';
import CartItem from '../cart_item';
import { useSelector, useDispatch } from 'react-redux';
import { addItem } from '../../redux/slices/cartSlice';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../redux/slices/cartSlice';

const CartItemPlace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = React.useState({
    fio: '',
    phone: '',
    email: '',
    checkbox: true,
  });
  function changeUseInfo(change, val) {
    setUserInfo({
      fio: change === 'fio' ? val : userInfo.fio,
      phone: change === 'phone' ? val : userInfo.phone,
      email: change === 'email' ? val : userInfo.email,
      checkbox: change === 'checkbox' ? !userInfo.checkbox : userInfo.checkbox,
    });
  }
  function sendOrder() {
    axios
      .post(
        'https://glavoblsnab.store/server/_index_server.php',
        {
          type_gos: 'send_order',
          user_info: userInfo,
          order: items,
          total_price: totalPrice,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => {
        //console.log(data);
        if (data.answer === 'order_confirm') {
          navigate('order_confirmed');
          dispatch(clearCart());
        } else alert('s');
        //dispatch(getData(data));
      })
      .then();
  }
  const [openOC, setOpenOC] = React.useState(false);
  const { items, totalPrice } = useSelector((state) => state.cart);

  // console.log(items);
  return (
    <div className={styles.root}>
      <div className={styles.items_place}>
        {items.length == 0 ? (
          <div className={styles.empty_cart_message}>Корзина пуста</div>
        ) : (
          items.map((el, i) => {
            return <CartItem key={i} props={el} />;
          })
        )}
      </div>
      {totalPrice != 0 && <div className={styles.itog_info}>итого : {totalPrice} ₽</div>}
      {totalPrice != 0 && (
        <div
          onClick={() => {
            setOpenOC(true);
            setUserInfo({
              fio: '',
              phone: '',
              email: '',
              checkbox: true,
            });
          }}
          className={styles.bayBTN}>
          <p>оформить заказ</p>
        </div>
      )}
      {openOC && (
        <div className={styles.order_confirm_div}>
          <div className={styles.conf_div}>
            <div className={styles.close_ok_btn_div}>
              <div
                className={styles.close_ok_btn}
                onClick={() => {
                  setOpenOC(false);
                }}>
                <p>x</p>
              </div>
            </div>

            <div className={styles.rules}>
              <p>
                Оставьте ваши контактные данные, и в ближайшее время с вами свяжется наш менеджер
                для завершения заказа
              </p>
            </div>
            <form className={styles.form_conf_ok}>
              <div className={styles.inp_div}>
                <label htmlFor='fio'>Ф.И.О :</label>
                <input
                  onChange={(e) => {
                    changeUseInfo('fio', e.target.value);
                  }}
                  id='fio'
                  type='text'
                />
              </div>
              <div className={styles.inp_div}>
                <label htmlFor='phone'>Телефон :</label>
                <input
                  onChange={(e) => {
                    changeUseInfo('phone', e.target.value);
                  }}
                  id='phone'
                  type='text'
                />
              </div>
              <div className={styles.inp_div}>
                <label htmlFor='email'>E-mail :</label>
                <input
                  onChange={(e) => {
                    changeUseInfo('email', e.target.value);
                  }}
                  id='email'
                  type='text'
                />
              </div>
              <div className={styles.check_div}>
                <p>Даю согласие на обработку предоставленных мной данных</p>
                <input
                  checked={userInfo.checkbox}
                  onChange={() => {
                    changeUseInfo('checkbox');
                  }}
                  type='checkbox'
                />
              </div>
            </form>
            <div className={styles.itogo}>
              <p>Общая сумма вашго заказа : {totalPrice} ₽</p>
            </div>
            <div
              onClick={() => {
                if (userInfo.fio != '' && userInfo.phone != '' && userInfo.email != '') {
                  if (!userInfo.checkbox) {
                    alert('для продолжения необходимо ваше согласие на обработку данных');
                  } else sendOrder();
                } else alert('заполните все поля');

                // console.log(userInfo);
                // console.log(items);
              }}
              className={styles.confirm_ok_btn}>
              Подтвердить
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartItemPlace;

import CartItemPlace from '../components/cart_items_place';
import HeaderPage from '../components/header_page';

const CartScreen = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <HeaderPage value={'КОРЗИНА'} />
      <CartItemPlace />
    </div>
  );
};

export default CartScreen;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalPrice: 0,
  items: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    //addItem(state, action) {
    //state.items.push(action.payload);
    //state.totalPrice = state.items.reduce((sum, obj) => {
    //return obj.price * 1 + sum;
    //}, 0);
    //},
    addItem(state, action) {
      const findItem = state.items.find(
        (obj) => obj.id === action.payload.id && obj.variant === action.payload.variant,
      );
      if (findItem) {
        findItem.count++;
      } else {
        state.items.push({
          ...action.payload,
          count: 1,
        });
      }
      state.totalPrice = state.items.reduce((sum, obj) => {
        return obj.price * 1 * obj.count + sum;
      }, 0);
    },
    removeItem(state, action) {
      //state.items.filter((obj) => obj.id != action.payload);
      const findItem = state.items.find(
        (obj) => obj.id === action.payload.id && obj.variant === action.payload.variant,
      );
      if (findItem) {
        findItem.count--;
      } else {
        state.items.push({
          ...action.payload,
          count: 1,
        });
      }
      state.totalPrice = state.items.reduce((sum, obj) => {
        return obj.price * 1 * obj.count + sum;
      }, 0);
    },
    clearItems(state, action) {
      //state.items = [];
      let newItems = [];
      const findItem = state.items.find(
        (obj) => obj.id === action.payload.id && obj.variant === action.payload.variant,
      );
      console.log(findItem);
      if (findItem) {
        state.items.forEach((obj) => {
          if (obj != findItem) newItems.push(obj);
        });
      }
      state.totalPrice = state.totalPrice - findItem.price * findItem.count;
      state.items = newItems;
    },
    clearCart(state) {
      state.items = [];
      state.totalPrice = 0;
    },
  },
});

export const { addItem, removeItem, clearItems, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
